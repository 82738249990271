import * as yup from 'yup';

export const schemaInutilizacao = yup
  .object({
    // ano_nf: yup.string().required(),
    cod_serie: yup.object().shape({
      value: yup.string().required(),
      label: yup.string().required(),
    }),
    inicio_nf: yup.string().required(),
    final_nf: yup.number().required(),
    motivo: yup.string().required().min(15).max(255),
  })
  .required();
