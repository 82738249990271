export const partesDecimais = [
  {
    label: '0',
    value: 0,
  },
  {
    label: '1/4',
    value: 1,
  },
  {
    label: '1/3',
    value: 2,
  },
  {
    label: '1/2',
    value: 3,
  },
  {
    label: '2/3',
    value: 4,
  },
  {
    label: '3/4',
    value: 5,
  },
];
