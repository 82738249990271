import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { GrFormClose } from 'react-icons/gr';
import apiInutilizacaoNfe from '../../services';
import moment from 'moment';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from '@material-ui/core';
import { Props, ResponseData } from '../../protocols';

/**
 * @description Componente responsável por apresentar o historico de
 * notas fiscais inutilizadas.
 *
 * @prop {boolean} showModal Prop responsável por controlar quando
 * será apresentado o modal.
 *
 * @prop {void} handleShowModal Função responsável pelo comportamento
 * de fechar o modal ao clicar no botão x.
 *
 * @prop {number} loja Prop auxiliar para realizar a busca de notas
 * fiscais apenas na loja selecionada.
 */
const ModalComponent: React.FC<Props> = ({
  showModal,
  handleShowModal,
  loja,
}) => {
  const [historico, setHistorico] = useState<any[]>([]);
  const [page, setPage] = useState<number>(0);
  const [count, setCount] = useState<number>(0);
  const [rowsPerPage] = useState<number>(5);

  const handleChangePage = async (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    async function fetchData() {
      const result: ResponseData = await apiInutilizacaoNfe.getHistorico(
        loja,
        page,
        rowsPerPage,
      );
      setCount(Number(result.count));
      setHistorico(result.data);
    }
    fetchData();
  }, [page]);

  return (
    <Modal show={showModal} centered size="xl">
      <Modal.Header>
        <Modal.Title>Histórico de Inutilização</Modal.Title>
        <GrFormClose
          size={20}
          style={{ cursor: 'pointer' }}
          onClick={handleShowModal}
        />
      </Modal.Header>
      <Modal.Body>
        <div
          style={{
            width: '100%',
            flex: 1,
            zIndex: 0,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TableContainer style={{ maxHeight: '400px', zIndex: '0' }}>
            <Table stickyHeader style={{ minHeight: 250 }}>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{ width: '10%' }}>
                    Loja
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    Ano
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    NF
                  </TableCell>
                  <TableCell align="center" style={{ width: '10%' }}>
                    Série
                  </TableCell>
                  <TableCell align="center" style={{ width: '20%' }}>
                    Motivo
                  </TableCell>
                  <TableCell align="center" style={{ width: '15%' }}>
                    Data
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ maxHeight: '100px' }}>
                {historico !== undefined && historico.length > 0 ? (
                  historico.map((item: any) => {
                    return (
                      <TableRow key={item.num_nf}>
                        <TableCell align="center">{item.cod_loja}</TableCell>
                        <TableCell align="center">{item.ano}</TableCell>
                        <TableCell align="center">{item.num_nf}</TableCell>
                        <TableCell align="center">{item.num_serie}</TableCell>
                        <TableCell align="center">{item.des_motivo}</TableCell>
                        <TableCell align="center">
                          {moment(item.dta_inclusao).format('DD/MM/YYYY')}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <div
                    style={{
                      left: '0',
                      right: '0',
                      marginTop: '3rem',
                      textAlign: 'center',
                      position: 'absolute',
                    }}
                  >
                    Nenhum registro encontrado.
                  </div>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[-1]}
            onPageChange={handleChangePage}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
          />
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default ModalComponent;
