import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px;

  #containe-busca-produto {
    padding: 0;
    margin: 0;

    button {
      width: 100%;
    }
  }
  .disabled-linear-progress {
    div {
      background: transparent !important;
    }
  }

  .toogle-inline {
    .toggleButton {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row-reverse;
    }
  }
  th {
    white-space: nowrap;
  }
  .tipo-0 {
    background: rgb(123, 66, 180) !important;
  }
  .tipo-1 {
    background: rgb(136, 80, 191) !important;
  }
  .tipo-2 {
    background: rgb(123, 66, 180) !important;
  }
  .tbody-margem tr td,
  .thead-margem tr th {
    min-width: 130px;
  }
  .MargemRef {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }
  .alingLeft {
    height: 70px;
    text-align: right;
  }

  .tabelaPreco .single-select {
    z-index: 1 !important;
  }

  @media screen and (max-width: 767px) {
    .containerTitle {
      max-width: 200px;
    }
    .containerChild {
      max-width: 200px !important;
      padding-left: 0px !important;
    }
  }

  .containerButtons {
    width: 100%;
    display: flex;
    flex-direction: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    align-content: flex-end;
    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .left {
      margin-right: auto;
      align-self: flex-end;
    }
    .right {
      order: 2;
      width: 100%;
      margin-top: 1.4rem;
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;
export const Title = styled.h3`
  margin-top: 20px;
  margin-bottom: 0px;
  color: #313638;
  font-size: calc(1.3rem + 0.6vw);
  line-height: 40px;
`;

export const SeparatorLine = styled.p`
  margin-top: 40px;
  margin-bottom: 0px;
  border-bottom: 1px solid #ededed;
  height: 5px;
  width: 99.95%;
`;

export const ButtonCancel = styled.button`
  height: 40px;
  min-width: 85px;
  border-radius: 4px;
  border: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 26.01px;
  animation: all 0.25s ease-in;
  margin: 0 5px;
  background-color: #dc3545;
  &:disabled {
    background-color: #e3bec1;
    cursor: not-allowed;
    &:hover {
      background-color: #e3bec1;
      opacity: 1;
    }
    // eslint-disable-next-line prettier/prettier
  }
  &:hover {
    opacity: 0.7;
  }
  box-shadow: transparent !important;
`;
export const ContainerLoader = styled.div`
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  padding: 16px 0px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
  margin-top: 20px;
  .containerButtons {
    width: 100%;
    display: flex;
    flex-direction: space-between;
    align-items: flex-end;
    flex-wrap: wrap-reverse;
    align-content: flex-end;
    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
    .text-warn {
      font-size: 1.2rem;
      margin-right: 0.875rem;
      line-height: 40px;
      font-weight: 450;
      color: red;
    }
    .left {
      margin-right: auto;
      align-self: flex-end;
    }
    .right {
      order: 2;
      width: 100%;
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
    }
  }
  .containerMaterial {
    display: flex;
    flex: 1;
    flex-direction: space-between;
    align-items: flex-end;

    .linkMaterial {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }

    .left {
      align-self: flex-end;
    }
    .right {
      // width: 100%;
      display: flex;
      flex: 2;
      justify-content: flex-end;
      align-items: center;
      background: red;
    }
  }

  /* .containerMaterial {
      min-width: fit-content;
      width: auto;
      padding: 0px 4px;
      order: 1;
      margin: 0px 64px 2px 0px;
    } */
  @media (max-width: 992px) {
    flex-direction: column;
    button {
      margin: 4px;
    }
    .containerButtons {
      flex-direction: column;
      align-items: flex-start;
    }
    .containerMaterial {
      order: 2 !important;
      margin-top: 16px;
    }
    .right {
      order: 1 !important;
      flex-direction: column;
      align-items: flex-start;
      max-width: fit-content;
    }
    .left {
      margin-left: 0 !important;
    }
  }
`;

export const ButtonForm = styled.button`
  padding: 3px 12px;
  height: 40px;
  border-radius: 4px;
  border: none;
  width: 160px;
  background-color: #28a745;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  line-height: 16px;

  svg {
    font-size: 20px;
    margin-right: 10px;
  }

  margin-right: 8px;
  margin-left: 8px;

  animation: all 0.25s ease-in;

  &.duplicate {
    background: #007bff;
    width: 300px;
  }
  &.return-search {
    background: #ffc107;
    width: 190px;
  }

  &.limpar {
    background: #ffc107;
  }

  &.cancelar {
    background: #dc3545;
  }

  &.history {
    width: 300px;
    background: #2b78e4;
    font-size: 14px;
  }
  &.executar {
    width: 240px;
    background: #9a2ff5;
    font-size: 14px;
  }

  &.delete {
    background: #dc3545;
  }

  &.disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  &.disabled:hover {
    opacity: 1;
  }

  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 992px) {
    width: 100% !important;
  }
`;
