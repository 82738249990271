import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  /* display: flex;
  justify-content: center;
  align-items: center; */
`;

export const SeparatorVertical = styled.div`
  border-left: 1px solid #ededed;
  width: 0.1px;
  margin: 0 auto;
`;

export const DescriptionField = styled.p`
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: #6b778c;
  font-weight: 600;
  display: inline-block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
`;
