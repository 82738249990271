export const medidasCaseiras = [
  {
    value: 0,
    label: '[00] = Colher(es) de Sopa',
  },
  {
    value: 1,
    label: '[01] = Colher(es) de Café',
  },
  {
    value: 2,
    label: '[02] = Colher(es) de Chá',
  },
  {
    value: 3,
    label: '[03] = Xícara(s)',
  },
  {
    value: 4,
    label: '[04] = De Xícara(s)',
  },
  {
    value: 5,
    label: '[05] = Unidade(s)',
  },
  {
    value: 6,
    label: '[06] = Pacote(s)',
  },
  {
    value: 7,
    label: '[07] = Fatia(s)',
  },
  {
    value: 8,
    label: '[08] = Fatia(s) Fina(s)',
  },
  {
    value: 9,
    label: '[09] = Pedaço(s)',
  },
  {
    value: 10,
    label: '[10] = Folha(s)',
  },
  {
    value: 11,
    label: '[11] = Pão(es)',
  },
  {
    value: 12,
    label: '[12] = Biscoito(s)',
  },
  {
    value: 13,
    label: '[13] = Bisnaguinha(s)',
  },
  {
    value: 14,
    label: '[14] = Disco(s)',
  },
  {
    value: 15,
    label: '[15] = Copo(s)',
  },
  {
    value: 16,
    label: '[16] = Porção(ões)',
  },
  {
    value: 17,
    label: '[17] = Tablete(s)',
  },
  {
    value: 18,
    label: '[18] = Sachê(s)',
  },
  {
    value: 19,
    label: '[19] = Almôdega(s)',
  },
  {
    value: 20,
    label: '[20] = Bife(s)',
  },
  {
    value: 21,
    label: '[21] = Filé(s)',
  },
  {
    value: 22,
    label: '[22] = Concha(s)',
  },
  {
    value: 23,
    label: '[23] = Bala(s)',
  },
  {
    value: 24,
    label: '[24] = Prato(s) Fundo(s)',
  },
  {
    value: 25,
    label: '[25] = Pitada(s)',
  },
  {
    value: 26,
    label: '[26] = Lata(s)',
  },
];
