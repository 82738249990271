export const porcoes = [
  {
    value: 1,
    label: 'G',
  },
  {
    label: 'ML',
    value: 2,
  },
  {
    label: 'UN',
    value: 3,
  },
];
