import React, { ChangeEvent, useState } from 'react';
import { ButtonForm, Container, Footer, SeparatorLine, Title } from './styles';
import { Col, Row } from 'react-bootstrap';
import Loja from '~/components/Loja';
import { getUserData } from '~/services/user';
import {
  InputAsyncSelect,
  InputNumber,
  InputText,
} from '~/components/NovosInputs';
import { useForm } from 'react-hook-form';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import { MdClearAll } from 'react-icons/md';
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaInutilizacao } from './validations';
import apiInutilizacaoNfe from './services';
import { FaHistory } from 'react-icons/fa';
import ModalComponent from './components/modal';
import { toast } from 'react-toastify';
import { RequestData, ResponseMessage } from './protocols';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import Tooltip from '@atlaskit/tooltip';
import { HotKeys, configure } from 'react-hotkeys';

import LoaderDefault from '~/components/DefaultLoader';
import { useQueryClient } from 'react-query';

const MySwal = withReactContent(Swal);

const InutilizacaoNfe: React.FC = () => {
  const {
    register,
    control,
    reset,
    watch,
    getValues,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schemaInutilizacao) });
  const queryClient = useQueryClient();
  const user = getUserData();
  const [lojas, setLojas] = useState(user?.loja);
  const [loader, setLoader] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  // Variável definida com o valor máximo sendo o maior inteiro que o banco permite
  const [safeInteger] = useState<number>(2147483647);

  configure({
    ignoreTags: ['input', 'select', 'textarea'],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ignoreEventsCondition(keyEvent: KeyboardEvent) {
      return false;
    },
  });

  /**
   * @description Função para realizar a requisição que inutiliza
   * as notas presentes no intervalo informado.
   */
  const onSave = async (): Promise<void> => {
    if (verificaTamanhoDoIntervalo()) {
      return;
    }
    const condicao: boolean = verificaIntervalo();

    const requestData: RequestData = {
      num_nf_ini: Number(getValues('inicio_nf')),
      num_nf_fim: Number(getValues('final_nf')),
      num_serie: getValues('cod_serie').value,
      motivo: getValues('motivo'),
      cod_loja: lojas,
    };
    if (condicao) {
      await MySwal.fire({
        title: 'Atenção',
        text: `A inutilização da NF-e é um procedimento irreversível. Deseja continuar? `,
        showCancelButton: true,
        confirmButtonColor: '#8850BF',
        cancelButtonColor: '#DE350B',
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoader(true);
          const resultInutilizacao: ResponseMessage =
            await apiInutilizacaoNfe.inutilizaNfe(requestData);
          if (resultInutilizacao.success) resetFields();
        }
        setLoader(false);
        return false;
      });
    }
  };

  /**
   * @description Função para verificar se o número informado
   * pelo usuário, onde o final_nf deverá ser maior ou igual
   * ao num_ini.
   *
   * @returns {boolean}
   */
  const verificaIntervalo = (): boolean => {
    const num_ini = Number(watch('inicio_nf'));
    const num_fim = Number(watch('final_nf'));
    if (num_fim >= num_ini) {
      return true;
    }
    toast.warn('Nº Final deve ser maior ou igual ao Nº Inicial');
    return false;
  };

  /**
   * @description Função para verificar se o intervalo informado
   * pelo usuário é menor que 100.
   *
   * @returns {boolean}
   */
  const verificaTamanhoDoIntervalo = (): boolean => {
    const num_ini = Number(watch('inicio_nf'));
    const num_fim = Number(watch('final_nf'));
    if (num_fim - num_ini > 100) {
      toast.warn(
        'A inutilização da NF-e suporta até 100 números por vez. Forneça um intervalo menor.',
      );
      return true;
    }
    return false;
  };

  const handleShowModal = (): void => {
    setShowModal(!showModal);
  };

  const resetFields = (): void => {
    reset({
      inicio_nf: '',
      final_nf: '',
      cod_serie: '',
      motivo: '',
      // ano_nf: '',
    });
    setLojas(user?.loja);
    setShowModal(false);
  };

  /**
   * @description Função que verifica se o valor copiado e
   * colado pelo usuário tem a existência de palavras.
   *
   */
  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (/[a-zA-Z]/.test(pastedText)) {
      const numericText = pastedText.replace(/[^0-9]/g, '');
      setValue('ano_nf', numericText);
      event.preventDefault();
    }
  };

  const handlePasteInputNumber = (
    event: React.ClipboardEvent<HTMLInputElement>,
  ) => {
    const { clipboardData } = event;
    const pastedText = clipboardData.getData('text');
    if (Number(pastedText) > safeInteger) {
      event.preventDefault();
    }
  };

  /**
   * @description Função que verifica se o valor digitado
   * no input tem o valor de "-" ou "e"(número de euller).
   *  */
  const handleKeyDown = (event: any) => {
    if ([69, 109, 107, 194, 110].includes(event.keyCode)) {
      event.preventDefault();
    }
  };

  /**
   * @description Função que verifica se o valor digitado
   * pelo usuário é maior que o max integer value que o
   * postgress pode receber.
   * @param event evento do input para pegar o value.
   * @param fieldName nome do campo para setar o valor do
   * react hook form.
   *
   */
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    fieldName: string,
  ) => {
    clearErrors(fieldName);

    if (Number(event.target.value) > safeInteger) {
      event.preventDefault();
      return;
    }
    setValue(fieldName, event.target.value);
  };

  if (loader) {
    return (
      <Container>
        <LoaderDefault />
      </Container>
    );
  }

  const keyMap = {
    hkClearFields: 'ctrl+alt+l',
  };

  const keyHandlers = {
    hkClearFields: () => {
      const cod_tela_active: any = queryClient.getQueryData(`cod_tela_active`);
      if (cod_tela_active) {
        if (cod_tela_active.cod_tela === 278) {
          resetFields();
        }
      }
    },
  };

  return (
    <HotKeys id="hotkeys" keyMap={keyMap} handlers={keyHandlers} tabIndex={-1}>
      <Container>
        <div className="containerButtons">
          <div className="left">
            <Title>Inutilização de NF-e</Title>
          </div>
          <div className="right">
            <ButtonForm className="history" onClick={handleShowModal}>
              <FaHistory />
              Histórico de Inutilização
            </ButtonForm>
          </div>
        </div>
        <SeparatorLine />
        {showModal && (
          <ModalComponent
            showModal={showModal}
            handleShowModal={handleShowModal}
            loja={lojas}
          />
        )}
        <Row className="mt-2">
          <Col lg={10} className="mt-2">
            <Loja
              selectedLoja={lojas}
              onChange={(val) => {
                if (val !== lojas) reset({ cod_serie: '' });
                setLojas(val);
              }}
            />
          </Col>
          {/* <Col lg={2}> */}
          {/**
           * @InputNumber:
           * Adição do @onChange poderia ser feita no componente como melhoria,
            pois quando digitamos um valor o onChange trata como uma string e a
            validação do maxLength acaba não acontecendo. Para corrigir basta
            implementarmos uma condição para quando o valor digitado pelo usuário
            ser maior que o maxLength fazer o slice da string e não atribuir ao
            value do react hook form.
            <InputNumber
              register={register}
              label="Ano"
              control={control}
              placeholder="0"
              isError={!!errors.ano_nf}
              name="ano_nf"
              type="number"
              min={0}
              maxLength={4}
              max={9999}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                clearErrors('ano_nf');
                if (event.target.value.length > 4) {
                  event.target.value = event.target.value.slice(0, 4);
                }
                setValue('ano_nf', event.target.value);
              }}
              onPaste={handlePaste}
              onKeyDown={handleKeyDown}
            />
          </Col> */}
          <Col lg={2}>
            <InputAsyncSelect
              label="Série"
              maxLength={50}
              placeholder="Série"
              name="cod_serie"
              register={register}
              isError={!!errors.cod_serie}
              control={control}
              changeSelected={(selected: any) => {
                clearErrors('cod_serie');
                setValue('cod_serie', selected);
              }}
              api={{
                route: `/inutilizaco-nfe/serie/${lojas}`,
                method: 'get',
                fields: ['num_serie'],
                searchBeforeFilter: true,
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={2}>
            <InputNumber
              register={register}
              label="Nº Inicial"
              placeholder="0"
              control={control}
              isError={!!errors.inicio_nf}
              name="inicio_nf"
              maxLength={safeInteger}
              min={0}
              max={safeInteger}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleChange(event, 'inicio_nf');
              }}
              onPaste={(e) => handlePasteInputNumber(e)}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col lg={2}>
            <InputNumber
              register={register}
              label="Nº Final"
              placeholder="0"
              control={control}
              isError={!!errors.final_nf}
              name="final_nf"
              maxLength={safeInteger}
              min={0}
              max={safeInteger}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                handleChange(event, 'final_nf');
              }}
              onPaste={(e) => handlePasteInputNumber(e)}
              onKeyDown={handleKeyDown}
            />
          </Col>
          <Col>
            <InputText
              register={register}
              label="Motivo da Inutilização"
              placeholder=""
              control={control}
              isError={!!errors.motivo}
              name="motivo"
              minLength={15}
              maxLength={255}
            />
          </Col>
        </Row>
        <Footer>
          <div className="containerButtons">
            <div className="right">
              <span className="text-warn">
                &ldquo;Processo Irreversível&ldquo;
              </span>
              <ButtonForm
                className="executar"
                type="button"
                onClick={() => handleSubmit(onSave)()}
              >
                <BsFillLightningChargeFill size={20} />
                Executar Inutilização
              </ButtonForm>
              <Tooltip position="bottom" content="CTRL + ALT + L">
                <ButtonForm className="limpar" onClick={resetFields}>
                  <MdClearAll /> Limpar Campos
                </ButtonForm>
              </Tooltip>
            </div>
          </div>
        </Footer>
      </Container>
    </HotKeys>
  );
};

export default InutilizacaoNfe;
