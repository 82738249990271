import { Select } from './Select';

export interface FormInformacaoNutricional {
  cod_info_nutricional: {
    value: string | number | undefined;
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_info_nutricional: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  porcao: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  unidade_porcao: {
    value: { label: string | undefined; value: number | undefined };
    isInvalid: boolean;
    isRequired: boolean;
  };
  des_porcao: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  valor_calorico: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  valor_kj: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_valor_calorico: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  carboidrato: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_carboidrato: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  proteina: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_proteina: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  gordura_total: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_gordura_total: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  gordura_saturada: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_gordura_saturada: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  gordura_trans: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_gordura_trans: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  colesterol: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_colesterol: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  fibra_alimentar: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_fibra_alimentar: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  calcio: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_calcio: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  ferro: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_ferro: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };

  sodio: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  vd_sodio: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  parte_inteira: {
    value: string;
    isInvalid: boolean;
    isRequired: boolean;
  };
  parte_decimal: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
  medida_utilizada: {
    value: Select;
    isInvalid: boolean;
    isRequired: boolean;
  };
}

export const formInformacaoNutricionalBlank: FormInformacaoNutricional = {
  cod_info_nutricional: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  des_info_nutricional: {
    value: '',
    isInvalid: true,
    isRequired: true,
  },
  porcao: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  valor_calorico: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  valor_kj: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  unidade_porcao: {
    value: { label: '', value: undefined },
    isInvalid: false,
    isRequired: false,
  },
  des_porcao: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_valor_calorico: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  carboidrato: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_carboidrato: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  proteina: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_proteina: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  gordura_total: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_gordura_total: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  gordura_saturada: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_gordura_saturada: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  gordura_trans: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_gordura_trans: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  colesterol: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_colesterol: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  fibra_alimentar: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_fibra_alimentar: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  calcio: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_calcio: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  ferro: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_ferro: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },

  sodio: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  vd_sodio: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  parte_inteira: {
    value: '',
    isInvalid: false,
    isRequired: false,
  },
  parte_decimal: {
    value: { label: undefined, value: 0 },
    isInvalid: false,
    isRequired: false,
  },
  medida_utilizada: {
    value: { label: undefined, value: 0 },
    isInvalid: false,
    isRequired: false,
  },
};
