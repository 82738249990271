import { toast } from 'react-toastify';
import api from '~/services/api';
import { RequestData, ResponseData, ResponseMessage } from '../protocols';

const apiInutilizacaoNfe = {
  inutilizaNfe: async (requestData: RequestData): Promise<ResponseMessage> => {
    try {
      const { data } = await api.post('/inutiliza-nfe', requestData);
      toast.success(data.message);
      return data;
    } catch (err: any) {
      return err.data;
    }
  },
  getHistorico: async (
    cod_loja: number,
    page: number,
    limit: number,
  ): Promise<ResponseData> => {
    try {
      const result = await api.get(
        `/inutilizacao-nfe/historico/${cod_loja}?limit=${limit}&page=${
          page + 1
        }&startAt=${page * limit}`,
      );
      return result.data;
    } catch (err: any) {
      return err.data;
    }
  },
};

export default apiInutilizacaoNfe;
